.body-details {
    background-color: #dfeef2;
    color: #444444;
    border-radius: 10px;
    caret-color: transparent;
}

.text-negrita {
    font-weight: bold;
}

.text-error {
    font-weight: bold;
    color: red;
}

.obs-text-dialog {
  text-align: center;
  font-size: 20px;
  font-weight: 700 !important;
  margin-bottom: 3px;
  margin-top: 20px;
}

.content-solicitude-observed {
    margin-left: 5%;
    margin-right: 2%;
}

.card-solicitude-observed {
    border-radius: 10px;
    // margin-right: 2%;
    background-color: white;
}

.card-solicitude-observed .text-subtitle {
    color: #444444;
    font-weight: bold;
}

.card-solicitude-observed .clickable {
    cursor: pointer;
}

.card-solicitude-observed .clickable label {
    cursor: pointer;
}

.card-solicitude-observed .card-inner {
    border-radius: 10px;
    background-color: #f6f6f6;
}

.card-solicitude-observed .card-inner:hover {
    background-color: #ebebeb;
}

.card-solicitude-observed .focus {
    border-style: solid;
    border-width: 1px;
    border-color: #0089b5;
}

.release-reject-request-dialog-content {
    padding: 25px;
    max-width: 400px;
    max-height: 700px;
    border-radius: 100px;
}

.release-reject-request-dialog-content .title {
    text-align: center;
    font-size: 14px;
    font-weight: 700 !important;
}

.release-reject-request-dialog-content .from-label {
    font-size: 13px;
}

.table-obs-historical-result {
    border-radius: 20px;
    padding: 0;
    overflow-x: hidden;
}

.table-obs-historical-result-style {
    tr:nth-child(odd) {
        background-color: #F6F6F6;
        ;
    }
}

.obs-confirm-dialog-content {
    padding: 20px;
    width: 400px;
}

.obs-confirm-dialog-title {
    text-align: center;
    font-size: 14px;
    font-weight: 700 !important;
    margin-bottom: 20px;
}

.obs-confirm-dialog-list-content {
    margin-bottom: 30px;
}