.common-circle-btn-tooltip {
  float: right !important;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: inherit;
  color: transparent;
  transition: all 0.3s ease;
}

.common-select-input-card {
  border-radius: 25px !important;
}

.common-select-input-card .MuiSelect-select {
  padding: 9px 20px;
}