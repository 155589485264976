$common-summary-schedule-table-border-radius: 10px;

.common-summary-schedule {
  margin: 0;
}

.common-summary-schedule .summary-table {
  border-radius: $common-summary-schedule-table-border-radius;

  thead {
    background-color: #e1e1e1;

    tr th {
      background-color: #e1e1e1;
      text-transform: uppercase;
    }

    th:first-child {
      padding-left: 1rem;
    }

    th:last-child {
      padding-right: 1rem;
    }
  }

  tbody {
    tr:nth-child(odd) {
      background-color: #f6f6f6;
    }

    td:first-child {
      padding-left: 1rem;
    }

    td:last-child {
      padding-right: 1rem;
    }
  }

  .left-rounded {
    border-top-left-radius: $common-summary-schedule-table-border-radius;
    border-bottom-left-radius: $common-summary-schedule-table-border-radius;
  }

  .right-rounded {
    border-top-right-radius: $common-summary-schedule-table-border-radius;
    border-bottom-right-radius: $common-summary-schedule-table-border-radius;
  }

  @mixin common-circle-btn($hover-bg-color) {
    float: left;
    border: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: inherit;
    color: transparent;
    transition: all 0.25s ease;
    height: 22px;
    width: 50px;

    &:hover {
      height: 22px;
      width: 58px;
      padding-right: 8px;
      border-radius: 100px;
      box-shadow: none;
      background-color: $hover-bg-color;
      color: #ffffff;
    }

    &:hover:before {
      transform: scale(1);
      opacity: 1;
    }

    &:focus {
      outline: none;
    }
  }

  .common-circle-btn-denegado {
    @include common-circle-btn(#fd4d02);
  }

  .common-circle-btn {
    @include common-circle-btn(#00af12);
  }

  .common-circle-btn-texto {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 11px;
    text-align: right;
    color: inherit;
  }
}