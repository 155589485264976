$common-details-schedule-table-border-radius: 10px;

.common-details-schedule {
  margin: 0;
}

.common-details-schedule .details-table {
  border-radius: $common-details-schedule-table-border-radius;

  thead {
    background-color: #e1e1e1;

    tr th {
      background-color: #e1e1e1;
      text-transform: uppercase;
    }

    th:first-child {
      padding-left: 1rem;
    }

    th:last-child {
      padding-right: 1rem;
    }
  }

  tbody {
    tr:nth-child(odd) {
      background-color: #f6f6f6;
    }

    td:first-child {
      padding-left: 1rem;
    }

    td:last-child {
      padding-right: 1rem;
    }
  }

  .left-rounded {
    border-top-left-radius: $common-details-schedule-table-border-radius;
    border-bottom-left-radius: $common-details-schedule-table-border-radius;
  }

  .right-rounded {
    border-top-right-radius: $common-details-schedule-table-border-radius;
    border-bottom-right-radius: $common-details-schedule-table-border-radius;
  }
}