.common-steps-img {
  position: relative;
  display: inline-block;
  text-align: center;
}

.common-steps-img-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}