$color-primary-pre-quotation: #ec0000;
$color-primary-hover-pre-quotation: #c20000;
$color-secondary-pre-quotation: #444444;
$color-secondary-hover-pre-quotation: #2f2f2f;
$color-disabled-pre-quotation: #e1e1e1;
$color-disabled-text-pre-quotation: #747474;
$color-text-pre-quotation: #ffffff;
$border-radius-pre-quotation: 24px;
$min-width-primary-pre-quotation: 15vw;
$min-width-action-pre-quotation: 10vw;

.content-pre-quotation {
  margin-left: 5%;
  margin-right: 2%;
}

.mlr-0-pre-quotation {
  margin-left: 0;
  margin-right: 0;
}

.button-pre-quotation {
  border-radius: $border-radius-pre-quotation !important;
  color: $color-text-pre-quotation !important;

  &.primary {
    background-color: $color-primary-pre-quotation !important;
    min-width: $min-width-primary-pre-quotation !important;

    &:hover {
      background-color: $color-primary-hover-pre-quotation !important;
    }

    &:disabled {
      background-color: $color-disabled-pre-quotation !important;
      color: $color-disabled-text-pre-quotation !important;
      cursor: not-allowed;
    }
  }

  &.secondary {
    background-color: $color-secondary-pre-quotation !important;
    min-width: $min-width-primary-pre-quotation !important;

    &:hover {
      background-color: $color-secondary-hover-pre-quotation !important;
    }

    &:disabled {
      background-color: $color-disabled-pre-quotation !important;
      color: $color-disabled-text-pre-quotation !important;
      cursor: not-allowed;
    }
  }

  &.action {
    background-color: $color-primary-pre-quotation !important;
    min-width: $min-width-action-pre-quotation !important;
    height: 41px !important;

    &:hover {
      background-color: $color-primary-hover-pre-quotation !important;
    }

    &:disabled {
      background-color: $color-disabled-pre-quotation !important;
      color: $color-disabled-text-pre-quotation !important;
      cursor: not-allowed;
    }
  }

  &.primary-circle {
    background-color: $color-primary-pre-quotation !important;
    min-width: 48px !important;
    border-radius: 50% !important;
    height: 48px !important;
    padding: 0px !important;

    &:hover {
      background-color: $color-primary-hover-pre-quotation !important;
    }

    &:disabled {
      background-color: $color-disabled-pre-quotation !important;
      color: $color-disabled-text-pre-quotation !important;
      cursor: not-allowed;
    }
  }
}