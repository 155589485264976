.displayProfile{
    text-align: right;
}

.card-title-start {
  margin-top: 0.5rem;
}

@media (max-width: 480px) {
  .card-title-start {
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .card-title-start {
    margin-top: 0;
  }
}