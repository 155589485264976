.headSesionStateSimbol{
    width: 40px;
    height: 40px;
    left: 0px;
    top: 1px;
    background: #DFEEF2;
    border-radius: 100px;
    text-align: center;
    align-content: center;
    padding-bottom: 2px;

    img {
      width: 30px;
      height: 30px;
    }
}

.divContenedorSimbol{
    text-align: "-webkit-right"
}
.css-1sf9zu4-JoyButton-root{
    border: none !important;
}