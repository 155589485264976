.wrapper {
  padding: 20px;
}

:root {
  --joy-radius-sm: 25px !important;
  --joy-radius-md: 25px !important;
  --joy-radius-lg: 25px !important;
}

@media screen and (min-width: 1250px) {
  .columnSolicitudNumber {
    display: block;
  }
}