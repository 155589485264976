//@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
$backgroundColor: #EC0000;
$textcolor:#ffffff;
$borderLeftColor:#58b5fc;

.navbar-menu {
  height:98%;
  background-color:$backgroundColor;
  box-shadow: 1px 1px 7px rgba(0,0,0,0.15);
  transition: all 0.3s ease;
  border-radius: 10px;
  position: fixed;
  z-index: 1000;
  margin-left: 0.5%;
  // width: 100%;
  overflow-y: auto; 
}

.navbar-menu::-webkit-scrollbar {
  width: 6px; 
}

.navbar-menu::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 4px; 
}

.navbar-menu::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
}

.burger{
  display:flex;
  justify-content: center;
  margin-bottom: 3rem;
  transition: all 0.3s ease;
  cursor: pointer;
}
.burger img {
  padding-top: 25px;
  padding-left: 2px;
  padding-right: 2px;
  max-width: 52px;
}
.navbar__list {
  list-style-type: none;
}
.navbar__li {
  display: inline-block;
  padding:0px 0px 0px 12px;
  position: relative;
  top:2.5px;
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 38px;
  color: $textcolor;
  font-family: "Lato";
  font-weight: 400;
}

.navbar__li_titulo{
  text-align: center;
  align-self: center;
}
/* .navbar__li-box:first-child {
  margin-top:25px;
  
} */
.navbar__li-box {
  display: inline-flex;
  width: 100%;
  margin-top: 15px;
  transition: all 0.3s ease;
}

.navbar__li-box:a {
  color: #fff;
  text-decoration: none;
}


.navbar__li-box:hover {
  border-left: 7px solid brown;
  cursor: pointer;
  
}
.ulCustomNav{
    padding-left: 0rem;
}
.TextPositionTituloNavbar{
  padding-top: 1.5rem;
}

.sub-options {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sub-option-link {
  display: inline-block;
  padding: 5px 10px;
  //background-color: #f2f2f2;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.sub-option-link:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
}

.sub-option-link:focus,
.sub-option-link:active {
  outline: none;
}

.image-container {
  margin-right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.image-container img {
  max-width: 34px;
  padding-top: 4px;
}

@media (max-width: 480px) {
  .navbar-menu {
    height:100%;
    margin: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .burger{
    margin-bottom: 1rem;
  }

  .burger img {
    padding-top: 0.5rem;
    padding-left: 2px;
    padding-right: 2px;
  }

  .navbar__li-box{
    margin-top: 0px;
  }

  .image-container img {
    padding-top: 4px;
    padding-left: 4px;
    padding-right: 4px;
  }
}

@media (max-width: 768px) {
  .navbar-menu {
    height:100%;
    margin: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .burger{
    margin-bottom: 1rem;
  }

  .burger img {
    padding-top: 0.5rem;
    padding-left: 2px;
    padding-right: 2px;
  }

  .navbar__li-box{
    margin-top: 0px;
  }

  .image-container img {
    padding-top: 4px;
    padding-left: 4px;
    padding-right: 4px;
  }
}